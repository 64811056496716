import ApplicationController from './application_controller';
import pdfjsLib from "pdfjs-dist/webpack";
import tippy, {hideAll} from "tippy.js";
import Panzoom from '@panzoom/panzoom';
import {v4 as uuidv4} from 'uuid';

export default class extends ApplicationController {
    static targets = [
        "canvas",
        "zoomIn",
        "zoomOut",
        "overlay",
        "toggleButton",
        "floorplan",
        "batchNamePatternLegend",
        "batchInsertOptions",
        "batchInsertCheckbox",
        "batchInsertSensorsCount",
        "batchInsertNamePattern",
        "batchInsertRows",
        "batchInsertColumns",
        "toggleBatchMoveButton",
    ];

    initialize() {
        this.scaleStep = 0.25;
        this.toggledProductId = null;
        this.debounceTimer = null;
        this.sizeChangeCounter = 0;
        this.iconScaleStep = 0.05;
        this.cursorElement = null;
        this.iconInitialWidth = 60;
        this.iconInitialHeight = 60;
        this.cursorListener = null;
        this.canInsertInBatch = true;
        this.panzoomInstances = [];

        // TODO move this to standalone stimulus controller (If it will work with this older stimulus version)
        this.boundMarkSensorsOnMouseDown = this.markSensorsOnMouseDown.bind(this);
        this.boundMarkSensorsOnMouseMove = this.markSensorsOnMouseMove.bind(this);
        this.boundMarkSensorsOnMouseUp = this.markSensorsOnMouseUp.bind(this);

        this.boundsMoveSelectedSensorsOnMouseDown = this.moveSelectedSensorOnMouseDown.bind(this);
        this.boundsMoveSelectedSensorsOnMouseMove = this.moveSelectedSensorOnMouseMove.bind(this);
        this.boundsMoveSelectedSensorsOnMouseUp = this.moveSelectedSensorOnMouseUp.bind(this);

        this.boundCancelBatchMoveOnEscape = this.cancelBatchMoveOnEscape.bind(this);

        this.startX = 0;
        this.startY = 0;
        this.endX = 0;
        this.endY = 0;
        this.isDrawing = false;
        this.isMovingSensor = false;
        this.selectionBox = null;
        this.sensorsSelected = false;
        this.originalSelectionBoxPosition = null;
        this.selectedSensors = [];

        this.canvasTargets.forEach(canvasTarget => {
            this.renderPage(canvasTarget, 1.0);
        })

        for (const button of this.toggleButtonTargets) {
            tippy(button, {
                content: button.dataset.installationNotes,
            });
        }

        tippy(this.batchNamePatternLegendTarget, {
            content: this.batchNamePatternLegendTarget.dataset.tooltip,
        });

        this.restoreInitialBatchMoveState();
    }

    disconnect() {
        this.removePanning();
    }

    getAllPlacements(floorplanWrapper) {
        return floorplanWrapper.querySelectorAll('.point.draggable');
    }

    getActiveFloorplanId() {
        let currentTab = document.querySelector('.controls ul li.active');
        return currentTab.getAttribute('data-tab-target').replace("floorplan-", "");
    }

    removeActiveStylesFromProductButtons() {
        for (let button of this.toggleButtonTargets) {
            button.style.color = button.dataset.color;
            button.style.backgroundColor = 'white';
            button.style.backgroundImage = `url(${button.dataset.backgroundImage})`;
            this.overlayTargets.forEach(overlayTarget => { overlayTarget.style.cursor = `auto`;});
        }
    }

    toggle(e) {
        const proposalLocked = document.querySelector('input[name="proposal_locked"]')?.checked
        if (proposalLocked) {
            return;
        }
        this.removeActiveStylesFromProductButtons();
        if (this.toggledProductId === event.target.dataset.productId) {
            this.toggledProductId = null;
            this.removeCursor();
            this.removeCursorListener(this.overlayTargets);
        } else {
            this.toggledProductId = event.target.dataset.productId;
            event.target.style.color = "white";
            event.target.style.backgroundColor = event.target.dataset.color;
            event.target.style.backgroundImage = `url(${event.target.dataset.inverseBackgroundImage})`;
            this.overlayTargets.forEach(overlayTarget => { overlayTarget.style.cursor = `url(${event.target.dataset.cursorImage}) 32 32, auto`; });

            if(this.batchInsertCheckboxTarget.checked) {
                this.updateCursorElement(e);
                this.addCursorListener(this.overlayTargets);
            }

            hideAll();
        }
    }


    activateProductButton(productButton) {
        productButton.style.color = "white";
        productButton.style.backgroundColor = productButton.dataset.color;
        productButton.style.backgroundImage = `url(${productButton.dataset.inverseBackgroundImage})`;
    }

    updateCursorElement(event) {

        if (!this.batchInsertCheckboxTarget.checked ) {
            return;
        }

        const iconScale = parseFloat(document.getElementById('floorplan-icon-scale').dataset.scale);
        const sensorsCount = parseInt(this.batchInsertSensorsCountTarget.value);
        const columns = parseInt(this.batchInsertColumnsTarget.value);
        const rows = Math.ceil(sensorsCount / columns);
        const rectWidth = this.iconInitialWidth * iconScale * columns;
        const rectHeight = this.iconInitialHeight * iconScale * rows;
        const cursor = this.cursorElement || document.createElement('div');

        cursor.style.width = Math.ceil(rectWidth) + 'px';
        cursor.style.height = Math.ceil(rectHeight) + 'px';
        cursor.style.position = 'absolute';
        cursor.style.zIndex = '100';
        cursor.style.pointerEvents = 'none';
        cursor.style.left = -100 + 'px';
        cursor.style.top = -100 + 'px';
        cursor.style.border = '2px dotted blue';
        document.body.appendChild(cursor);
        this.cursorElement = cursor;
    }

    removeCursor() {
        if (this.cursorElement) {
            this.cursorElement.remove();
            this.cursorElement = null;
        }
    }

    addCursorListener(overlayElements) {
        if (this.cursorListener) {
            return;
        }
        this.cursorListener = (e) => {
            const iconScale = parseFloat(document.getElementById('floorplan-icon-scale').dataset.scale);
            this.cursorElement.style.left = e.clientX + window.scrollX - (this.iconInitialWidth * iconScale / 2) + 'px';
            this.cursorElement.style.top = e.clientY + window.scrollY - (this.iconInitialHeight / 2 * iconScale) + 'px';
            this.cursorFitOverlay(e)
        };

        overlayElements.forEach(overlayElement => overlayElement.addEventListener('mousemove', this.cursorListener));
    }

    cursorFitOverlay(e) {
        const overlay = e.currentTarget.closest('.overlay');
        const overlayRect = overlay.getBoundingClientRect();
        const cursorRect = this.cursorElement.getBoundingClientRect();
        const maxX = e.clientX + cursorRect.width - this.iconInitialWidth / 2
        const maxY = e.clientY + cursorRect.height - this.iconInitialHeight / 2
        const minX = e.clientX  - this.iconInitialWidth / 2
        const minY = e.clientY - this.iconInitialHeight / 2

        if (minX > overlayRect.x && maxX < overlayRect.x + overlayRect.width &&
            minY > overlayRect.y && maxY < overlayRect.y + overlayRect.height) {
            this.cursorElement.style.border = '2px dotted blue';
            this.canInsertInBatch = true;
            return true;
        }
        else {
            this.cursorElement.style.border = '2px dotted red';
            this.canInsertInBatch = false;
            return false;
        }
    }

    removeCursorListener(overlayElements) {
        overlayElements.forEach(overlayElement => overlayElement.removeEventListener('mousemove', this.cursorListener));
        this.cursorListener = null;
    }


    add(event) {
        if (this.toggledProductId === null || this.overlayTarget.disabled === true) {
            return;
        }

        if(this.batchInsertCheckboxTarget.checked && !this.canInsertInBatch) {
            return;
        }

        this.overlayTargets.forEach(overlayTarget => { overlayTarget.disabled = true; });
        const spinnerElement = event.currentTarget.querySelector('#spinner');
        this.spinnerOn(spinnerElement);
        var rect = event.target.getBoundingClientRect();
        var x = (event.clientX - rect.left) / event.target.offsetWidth;
        var y = (event.clientY - rect.top) / event.target.offsetHeight;
        var currentTab = document.querySelector(".controls ul li.active");
        var placementId = uuidv4();
        var busId = document.querySelector("#busable_id").value;
        let controller = this;
        let placementNumber = document.querySelector("#placement_number").value;
        let overlayTargets = this.overlayTargets;
        let productId = this.toggledProductId;

        let scaleElement = document.getElementById('floorplan-icon-scale');
        let scaleTo = parseFloat(scaleElement.dataset.scale) + this.iconScaleStep;

        const rowGap = this.iconInitialHeight / event.target.offsetHeight;
        const columnGap = this.iconInitialWidth / event.target.offsetWidth;

        const batchInsertOptions = {
            batch_insert: this.batchInsertCheckboxTarget.checked,
            sensors_count: parseInt(this.batchInsertSensorsCountTarget.value),
            name_pattern: this.batchInsertNamePatternTarget.value,
            scaling_factor: scaleTo,
            row_gap: rowGap,
            column_gap: columnGap,
            insert_options: {
                rows: parseInt(this.batchInsertRowsTarget.value),
                columns: parseInt(this.batchInsertColumnsTarget.value),
            },
        };

        this.stimulate("PlacementReflex#add", event.target, busId, productId, x, y, placementNumber, batchInsertOptions)
      .then(payload => {
        this.batchInsertCheckboxTarget.checked = false
        const success_event = new CustomEvent('save-indicator:success', { detail: payload });
        window.dispatchEvent(success_event);

        if (!document.querySelector('input[id="floorplan-editor-add_batch"]').checked) {
          controller.removeActiveStylesFromProductButtons();
        } else {
          let productDiv = document.querySelector('button[data-product-id="' + productId + '"]');
          controller.toggledProductId = productId;
          controller.activateProductButton(productDiv);
          overlayTargets.forEach(overlayTarget => { overlayTarget.style.cursor = `url(${productDiv.dataset.cursorImage}) 32 32, auto`;});
        }

                // TODO ugly same code is in tabs_controller
                document.querySelectorAll('.controls ul li').forEach(
                    function (tabSelector) {
                        if (tabSelector.dataset.tabTarget === currentTab.dataset.tabTarget) {
                            tabSelector.classList.add('active');
                        } else {
                            tabSelector.classList.remove('active');
                        }
                    }
                );

                document.querySelectorAll('.placements').forEach(
                    function (floorplan) {
                        if (floorplan.id === currentTab.dataset.tabTarget) {
                            floorplan.style.display = currentTab.dataset.display || 'block';
                        } else {
                            floorplan.style.display = 'none';
                        }
                    }
                );

                var addedPoint = document.getElementById(`point-${placementId}`);
                addedPoint._tippy.show();
            })
            .catch(payload => {
                const failed_event = new CustomEvent('save-indicator:failed', {detail: payload});
                window.dispatchEvent(failed_event);
            })
            .finally(() => {
                overlayTargets.forEach(overlayTarget => { overlayTarget.disabled = false; });
                this.spinnerOff(spinnerElement);
                this.removeCursorListener(this.overlayTargets);
            });
    }

    zoomIn(event) {
        this.zoomInTarget.disabled = true;
        this.zoomOutTarget.disabled = true;
        let placementsDiv = event.currentTarget.closest('.placements')
        let scaleStep = parseFloat(document.querySelector('#scale_step').value);
        let scale = parseFloat(placementsDiv.querySelector('#floorplan-scale').dataset.scale);
        scale = scale + scaleStep;
        placementsDiv.querySelector('#floorplan-scale').dataset.scale = scale;
        let floorplanId = placementsDiv.id.replace('floorplan-', '');
        console.log(scale);
        this.renderPage(placementsDiv.querySelector('#canvas-' + floorplanId), scale);
    }

    zoomOut() {
        this.zoomInTarget.disabled = true;
        this.zoomOutTarget.disabled = true;
        let placementsDiv = event.currentTarget.closest('.placements')
        let scaleStep = parseFloat(document.querySelector('#scale_step').value);
        let scale = parseFloat(placementsDiv.querySelector('#floorplan-scale').dataset.scale);
        if (scale - scaleStep > 0) {
            scale -= scaleStep;
            placementsDiv.querySelector('#floorplan-scale').dataset.scale = scale;
            let floorplanId = placementsDiv.id.replace('floorplan-', '');
            console.log(scale);
            this.renderPage(placementsDiv.querySelector('#canvas-' + floorplanId), scale);
        } else {
            this.zoomInTarget.disabled = false;
        }
    }

    increaseIconSize() {
        let scaleElement = event.currentTarget.closest('#floorplan-icon-scale');
        let floorplanId = scaleElement.closest('.placements').id.replace("floorplan-", "");
        let scaleTo = parseFloat(scaleElement.dataset.scale) + this.iconScaleStep;
        scaleElement.dataset.scale = scaleTo
        this.resetAndStartDebounceTimer(scaleElement.dataset.scale, floorplanId);
        this.scaleFloorplanPlacements(scaleTo, floorplanId);
    }

    decreaseIconSize() {
        let scaleElement = event.currentTarget.closest('#floorplan-icon-scale');
        let floorplanId = scaleElement.closest('.placements').id.replace("floorplan-", "");
        let scaleTo = parseFloat(scaleElement.dataset.scale) - this.iconScaleStep;
        scaleElement.dataset.scale = scaleTo
        this.resetAndStartDebounceTimer(scaleElement.dataset.scale, floorplanId);
        this.scaleFloorplanPlacements(scaleTo, floorplanId);
    }

    scaleFloorplanPlacements(scale, floorplanId) {
        let floorplanWrapper = document.querySelector('#floorplan-' + floorplanId).querySelector('.floorplan .wrapper');
        let placementsWithActiveFloorplan = this.getAllPlacements(floorplanWrapper);

        placementsWithActiveFloorplan.forEach(placement => {
            let x_size = placement.textContent.trim().length * 6 * scale;
            let place_x_size = x_size * 1.4;
            placement.style['width'] = place_x_size + 'px';
            placement.style['min-width'] = place_x_size + 'px';
            placement.style['background-size'] =  floorplanWrapper.dataset.iconSizeWidth  * scale + 'px ' +floorplanWrapper.dataset.iconSizeHeight  * scale  + 'px';
            placement.style['left'] = 'calc(' + placement.dataset.x * 100 + '% - ' + parseFloat(floorplanWrapper.dataset.iconSizeOuterWidth)  * scale / 2 + 'px)';
            placement.style['top'] = 'calc(' + placement.dataset.y * 100 + '% - ' + parseFloat(floorplanWrapper.dataset.iconSizeHeight) * scale / 2 + 'px)';
            placement.style['height'] = floorplanWrapper.dataset.iconSizeHeight * scale + 'px';
            let sensor = placement.querySelector('.sensor');

            if (sensor ) {
                sensor.style['font-size'] = floorplanWrapper.dataset.iconSizeFontSize * scale + 'px';
                sensor.style['height'] = floorplanWrapper.dataset.iconSizeHeight * scale + 'px';
                sensor.style['line-height'] = sensor.style['height'];
                sensor.style['border-radius'] = 10 * scale + 'px';
                sensor.style['top'] = 'calc(-20px - '  + (floorplanWrapper.dataset.iconSizeHeight * scale - floorplanWrapper.dataset.iconSizeHeight) + 'px)';
            } else {
                let centralUnit = placement.querySelector('.central-unit');
                centralUnit.style['font-size'] = floorplanWrapper.dataset.iconSizeFontSize * scale + 'px';
                centralUnit.style['height'] = floorplanWrapper.dataset.iconSizeHeight * scale + 'px';
                centralUnit.style['line-height'] = centralUnit.style['height'];
                centralUnit.style['width'] = floorplanWrapper.dataset.iconSizeWidth * scale * 2 + 'px';
                centralUnit.style['border-radius'] = 10 * scale + 'px';

            }
        });
    }

    resetAndStartDebounceTimer(currentScale) {
        clearTimeout(this.debounceTimer);
        this.debounceTimer = setTimeout(() => { this.changeIconSize(currentScale); }, 300);
    }

    changeIconSize(scale) {
        this.stimulate('PlacementReflex#update_sensors', {
                scale: scale,
                floorplan_id: this.getActiveFloorplanId()
            }
        ).then((payload) => {
            console.log("Scale icons saved for floorplan ")
        })

    }

    renderPage(canvas, scale) {
        // display: 'none' implies no size and thus the element can be positioned via interactjs
        $('.point').css('visibility', 'hidden');
        var controller = this;

        var loadingTask = pdfjsLib.getDocument(canvas.dataset.fileUrl);
        loadingTask.promise.then(function (pdf) {
            var page = pdf.getPage(1);
            page.then(function (page) {
                var viewport = page.getViewport({scale: scale});

                var context = canvas.getContext("2d");
                canvas.height = viewport.height;
                canvas.width = viewport.width;

                var renderContext = {
                    canvasContext: context,
                    viewport: viewport,
                };

                var renderTask = page.render(renderContext);
                renderTask.promise.then(function () {
                    $('.point').css('visibility', 'visible');
                    controller.zoomInTarget.disabled = false;
                    controller.zoomOutTarget.disabled = false;
                });
            });
            document.querySelector('.floorplan').style = '';
        });
    }

    fullScreen(event) {
        document.querySelector("body > main > div.container").classList.toggle('fullscreen');
        document.querySelectorAll(".floorplan").forEach(function (floorplan) {
            floorplan.classList.toggle('fullscreen');
        });
    }

    setupPanning() {
        this.floorplanTargets.forEach(floorplanTarget => {
            this.panzoomInstances.push(Panzoom(
                floorplanTarget,
                {
                    //excludeClass: 'point',
                    disableZoom: true,
                    overflow: 'scroll',
                    handleStartEvent: (event) => {
                        event.preventDefault();
                        hideAll();
                    },
                }
            ));
        });
    }

    removePanning() {
        this.panzoomInstances.forEach(panzoomInstance => {
            panzoomInstance.destroy();
        });
        this.panzoomInstances = [];
    }

    markSensorsOnMouseDown(event) {
        this.startX = event.clientX + window.scrollX;
        this.startY = event.clientY + window.scrollY;
        this.isDrawing = true;

        this.selectionBox = document.createElement('div');
        this.selectionBox.style.position = 'absolute';
        this.selectionBox.style.border = '2px dashed red';
        this.selectionBox.style.pointerEvents = 'none';
        this.selectionBox.style.zIndex = '100';
        document.body.appendChild(this.selectionBox);
    }

    moveSelectedSensorOnMouseDown(event) {
        if (!this.sensorsSelected) return;

        this.startX = event.clientX + window.scrollX;
        this.startY = event.clientY + window.scrollY;
        this.isMovingSensor = true;
    }

    markSensorsOnMouseMove(event) {
        if (!this.isDrawing) return;
        this.endX = event.clientX + window.scrollX;
        this.endY = event.clientY + window.scrollY;

        this.selectionBox.style.left = Math.min(this.startX, this.endX) + 'px';
        this.selectionBox.style.top = Math.min(this.startY, this.endY) + 'px';
        this.selectionBox.style.width = Math.abs(this.startX - this.endX) + 'px';
        this.selectionBox.style.height = Math.abs(this.startY - this.endY) + 'px';

    }

    moveSelectedSensorOnMouseMove(event) {
        if (!this.sensorsSelected || !this.isMovingSensor) return;

        const deltaX = event.clientX + window.scrollX - this.startX;
        const deltaY = event.clientY + window.scrollY - this.startY;

        const rect = this.selectionBox.getBoundingClientRect();

        this.selectionBox.style.left = this.originalSelectionBoxPosition.left + deltaX + 'px';
        this.selectionBox.style.top = this.originalSelectionBoxPosition.top + deltaY + 'px';

        this.selectedSensors.forEach(sensor => {
            this.moveSensor(sensor, deltaX, deltaY);
        });
    }

    markSensorsOnMouseUp(event) {
        if (!this.isDrawing) return;

        this.isDrawing = false;
        this.sensorsSelected = true;

        const rect = this.selectionBox.getBoundingClientRect();

        const selectionBoxTop = parseInt(this.selectionBox.style.top);
        const selectionBoxLeft = parseInt(this.selectionBox.style.left);
        this.originalSelectionBoxPosition = {top: selectionBoxTop, left: selectionBoxLeft};
        this.sensorsInBoundary(event.currentTarget.closest('.overlay'));

        this.removeMarkSensorsHandlers()
        this.addMoveSelectedSensorsHandlers()
    }

    moveSelectedSensorOnMouseUp(event) {
        if (!this.sensorsSelected) return;
        if (!this.selectedSensors.length) {
            this.restoreInitialBatchMoveState();
            return;
        }
        this.isMovingSensor = false;
        const selectionBoxTop = parseInt(this.selectionBox.style.top);
        const selectionBoxLeft = parseInt(this.selectionBox.style.left);
        this.originalSelectionBoxPosition = {top: selectionBoxTop, left: selectionBoxLeft};
        this.selectedSensors.forEach(sensor => {
            sensor.originalPosition.x = sensor.element.dataset.x;
            sensor.originalPosition.y = sensor.element.dataset.y;
        });

        const currentOverlay = event.currentTarget.closest('.overlay');

        this.spinnerOn(currentOverlay.querySelector('#spinner'));

        this.overlayTargets.forEach(overlayTarget => {
            overlayTarget.style.pointerEvents = 'none';
        });

        this.stimulate('PlacementReflex#batch_move', this.selectedSensors.map(sensor => {
                return {
                    id: sensor.element.dataset.placementId,
                    pos_x: sensor.element.dataset.x,
                    pos_y: sensor.element.dataset.y,
                }
            })
        ).then((payload) => {
            this.overlayTargets.forEach(overlayTarget => {
                overlayTarget.style.pointerEvents = 'auto';
            });
            this.spinnerOff(currentOverlay.querySelector('#spinner'));
        })
    }

    cancelBatchMoveOnEscape(event) {
        if (event.key === 'Escape') {
            this.toggleBatchMove(event);
        }
    }

    addMarkSensorsHandlers() {

        this.overlayTargets.forEach(overlayTarget => {
            overlayTarget.addEventListener('mousedown', this.boundMarkSensorsOnMouseDown);
            overlayTarget.addEventListener('mousemove', this.boundMarkSensorsOnMouseMove);
            overlayTarget.addEventListener('mouseup', this.boundMarkSensorsOnMouseUp);
        });
    }

    addMoveSelectedSensorsHandlers() {
        this.overlayTargets.forEach(overlayTarget => {
            overlayTarget.addEventListener('mousedown', this.boundsMoveSelectedSensorsOnMouseDown);
            overlayTarget.addEventListener('mousemove', this.boundsMoveSelectedSensorsOnMouseMove);
            overlayTarget.addEventListener('mouseup',  this.boundsMoveSelectedSensorsOnMouseUp);
        });
    }

    removeMarkSensorsHandlers() {
        this.overlayTargets.forEach(overlayTarget => {
            overlayTarget.removeEventListener('mousedown', this.boundMarkSensorsOnMouseDown);
            overlayTarget.removeEventListener('mousemove', this.boundMarkSensorsOnMouseMove);
            overlayTarget.removeEventListener('mouseup', this.boundMarkSensorsOnMouseDown);
        });
    }

    removeMoveSelectedSensorsHandlers() {
        this.overlayTargets.forEach(overlayTarget => {
            overlayTarget.removeEventListener('mousedown', this.boundsMoveSelectedSensorsOnMouseDown);
            overlayTarget.removeEventListener('mousemove', this.boundsMoveSelectedSensorsOnMouseMove);
            overlayTarget.removeEventListener('mouseup', this.boundsMoveSelectedSensorsOnMouseUp);
        });
    }

    sensorsInBoundary(overlay) {
        if(!this.selectionBox) return

        const rect = this.selectionBox.getBoundingClientRect();

        this.getAllPlacements(overlay).forEach(placement => {
            const placementRect = placement.getBoundingClientRect();

            if (placementRect.left >= rect.left &&
                placementRect.right <= rect.right &&
                placementRect.top >= rect.top &&
                placementRect.bottom <= rect.bottom) {
                this.selectedSensors.push({
                    element: placement,
                    originalPosition: {
                        x: placement.dataset.x,
                        y: placement.dataset.y,
                    }
                });
            }
        })
    }

    moveSensor(sensor, deltaX, deltaY) {
        const overlayTarget = sensor.element.closest('.overlay')

        const x = ((parseFloat(sensor.originalPosition.x) || 0) * overlayTarget.clientWidth) - sensor.element.offsetWidth/2 + deltaX;
        const y = ((parseFloat(sensor.originalPosition.y) || 0) * overlayTarget.clientHeight) - sensor.element.offsetHeight/2 + deltaY;

        sensor.element.dataset.x = (x + sensor.element.offsetWidth/2) / overlayTarget.clientWidth;
        sensor.element.dataset.y = (y + sensor.element.offsetHeight/2) / overlayTarget.clientHeight;
        sensor.element.style.left = 'calc(' + sensor.element.dataset.x * 100 + '%' + ' - ' + sensor.element.offsetWidth/2 +'px)';
        sensor.element.style.top = 'calc(' + sensor.element.dataset.y * 100 + '%' + ' - ' + sensor.element.offsetHeight/2 +'px)';
    }

    restoreInitialBatchMoveState() {
        this.toggleBatchMoveButtonTarget.classList.remove('active');
        this.removeMarkSensorsHandlers();
        this.removeMoveSelectedSensorsHandlers();
        document.removeEventListener('keydown', this.boundCancelBatchMoveOnEscape);

        if (this.panzoomInstances.length === 0) {
            this.setupPanning();
        }

        if (this.selectionBox) {
            this.selectionBox.remove();
        }

        this.isDrawing = false;
        this.isMovingSensor = false;
        this.selectionBox = null;
        this.sensorsSelected = false;
        this.originalSelectionBoxPosition = null;
        this.selectedSensors = [];
    }

    prepareBatchMoveState() {
        this.toggleBatchMoveButtonTarget.classList.add('active');
        this.removePanning();
        this.addMarkSensorsHandlers();
        document.addEventListener('keydown', this.boundCancelBatchMoveOnEscape);
    }

    toggleBatchMove(e) {
        this.toggleBatchMoveButtonTarget.classList.contains('active') ? this.restoreInitialBatchMoveState() : this.prepareBatchMoveState();
    }
}
