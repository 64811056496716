import ModalController from './../modal_controller';
import flatpickr from 'flatpickr';

export default class extends ModalController {
  static targets = ["paymentFor"];

  setDueAt(_selectedDates, dateStr, flatpickr_instance) {
    event.preventDefault();
    this.stimulate('Invoice#set_due_at', flatpickr_instance.element, flatpickr_instance.element.dataset.id, dateStr).then(payload => {
      this.flatpickrToAllDeliveryForms()
    });
  }

  setPaidAt(_selectedDates, dateStr, flatpickr_instance) {
    event.preventDefault();
    this.stimulate('Invoice#set_paid_at', flatpickr_instance.element, flatpickr_instance.element.dataset.id, dateStr).then(payload => {
      this.flatpickrToAllDeliveryForms()
    });
  }

  destroyInvoice(event) {
    let form = event.srcElement.closest('form');
    let spinnerDiv = form.querySelector('#spinner');
    this.spinnerOn(spinnerDiv);
    this.stimulate(
        'Billing::InvoiceReflex#destroy_invoice',
        event.target
    ).then(_payload => {
      document.querySelector('#invoices').scrollIntoView();
    });
  }

  sendInvoice(event) {
    console.log("sendInvoice");
    this.stimulate(
        'Billing::InvoiceReflex#send_invoice',
        event.target
    ).then(_payload => {
      document.querySelector('#invoices').scrollIntoView();
    });
  }

  showDestroyInvoiceModal(event) {
    super.show(event);

    this.stimulate(
        'Billing::InvoiceReflex#show_destroy_invoice',
        event.target
    ).then(_payload => {
      location.reload();
    });
  }

  assingFlatpickr(input) {
    var controller = this;
    flatpickr(input, {
      locale: this.getLocale(),
      dateFormat: 'd.m.Y',
    });
  }
}